/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
function addParameter(url, parameterName, parameterValue, atStart/*Add param before others*/) {
  var replaceDuplicates = true;
  var urlhash = '';
  let cl;
  if (url.indexOf('#') > 0) {
    cl = url.indexOf('#');
    urlhash = url.substring(url.indexOf('#'), url.length);
  } else {

    cl = url.length;
  }
  var sourceUrl = url.substring(0, cl);

  var urlParts = sourceUrl.split("?");
  var newQueryString = "";

  if (urlParts.length > 1) {
    var parameters = urlParts[1].split("&");
    for (var i = 0; (i < parameters.length); i++) {
      var parameterParts = parameters[i].split("=");
      if (!(replaceDuplicates && parameterParts[0] === parameterName)) {
        if (newQueryString === "") {
          newQueryString = "?";
        } else {
          newQueryString += "&";
        }
        newQueryString += parameterParts[0] + "=" + (parameterParts[1] ? parameterParts[1] : '');
      }
    }
  }
  if (newQueryString === "") {
    newQueryString = "?";
  }

  if (atStart) {
    newQueryString = '?' + parameterName + "=" + parameterValue + (newQueryString.length > 1 ? '&' + newQueryString.substring(1) : '');
  } else {
    if (newQueryString !== "" && newQueryString !== '?') {
      newQueryString += "&";
    }
    newQueryString += parameterName + "=" + (parameterValue ? parameterValue : '');
  }
  return urlParts[0] + newQueryString + urlhash;
}

const request = (route, data) => {
  return fetch('/wp-json/aksr/v1' + route, {
    method: 'POST',
    body: data ? JSON.stringify(data) : undefined,
    headers: {
      'Content-Type': 'application/json',
    },
  })
   .then((res) => {
    if (!res.ok) {
      throw new Error('Invalid request');
    }
    return res.json();
  })
   .catch(console.error);
};

window.addToCart = (redirectTo, productId, meta) => {
  request('/cart/add/' + productId, meta).then(() => {
    window.location.href = redirectTo;
  });
  return false;
};

window.updateCart = (itemIndex, count) => {
  request(`/cart/update/${itemIndex}`, {count}).then(() => {
    window.location.reload();
  });

  return false;
};

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {

        // JavaScript to be fired on all pages
        $(".navbar-toggle").click(function () {
          $(this).toggleClass("active");
        });


        $('.error-summary li').click(function () {
          $($(this).attr('data-target')).focus();
        });

        $('.popup').click(function (event) {

          var width = 575,
            height = 400,
            left = ($(window).width() - width) / 2,
            top = ($(window).height() - height) / 2,
            url = this.href,
            opts = 'status=1' +
              ',width=' + width +
              ',height=' + height +
              ',top=' + top +
              ',left=' + left;

          window.open(url, 'sharer', opts);

          return false;
        });
      },
      finalize: function () {


        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'page_template_template_about': {
      init: function () {
        //TODO: Make this without JS!
        // JavaScript to be fired on the about us page
        var el = $('<div>');
        el.addClass("collapse");
        //el.addClass("in");

        el.addClass("responsive-content");
        el.html($("#druha-sekcia > p:not(:first-of-type)"));
        $("#druha-sekcia").html([$("#druha-sekcia > p:first-of-type"), el]);


        $("#RegistrationForm").forms({'validation': true});
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS

      }
    },
    'page_template_template_cart': {

      init: function () {

        if ($("#OrderForm_shipping_same").is(':checked')) {
          $("#shippingModule").hide();
        }


        $("#OrderForm_shipping_same").change(function (e) {
          //console.log($(this).is(':checked'));
          if ($(this).is(':checked')) {
            $("#shippingModule").hide();
          } else {
            $("#shippingModule").show();
          }

          $("#shippingModule input, #shippingModule select, #shippingModule textarea").each(function (i, t) {
            if ($("#OrderForm_shipping_same").is(':checked')) {
              $(t).attr('disabled', 'disabled');
            } else {
              $(t).attr('disabled', false);
            }
          });
        });
      },
      finalize: function () {

      }
    },
    'page_template_template_contact': {
      init: function () {
        /*
         *  add_marker
         *
         *  This function will add a marker to the selected Google Map
         *
         *  @type	function
         *  @date	8/11/2013
         *  @since	4.3.0
         *
         *  @param	$marker (jQuery element)
         *  @param	map (Google Map object)
         *  @return	n/a
         */

        function add_marker($marker, map) {

          // var
          var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));

          // create marker
          var marker = new google.maps.Marker({
            position: latlng,
            map: map,
            title: 'daco'
          });

          // add to array
          map.markers.push(marker);

          // if marker contains HTML, add it to an infoWindow
          if ($marker.html()) {
            // create info window
            var infowindow = new google.maps.InfoWindow({
              content: $marker.html()
            });

            // show info window when marker is clicked
            google.maps.event.addListener(marker, 'click', function () {

              infowindow.open(map, marker);

            });
            infowindow.open(map, marker);
          }

        }

        /*
         *  center_map
         *
         *  This function will center the map, showing all markers attached to this map
         *
         *  @type	function
         *  @date	8/11/2013
         *  @since	4.3.0
         *
         *  @param	map (Google Map object)
         *  @return	n/a
         */

        function center_map(map) {

          // vars
          var bounds = new google.maps.LatLngBounds();

          // loop through all markers and create bounds
          $.each(map.markers, function (i, marker) {

            var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());

            bounds.extend(latlng);

          });

          // only 1 marker?
          if (map.markers.length === 1) {
            // set center of map
            map.setCenter(bounds.getCenter());
            map.setZoom(16);
          }
          else {
            // fit to bounds
            map.fitBounds(bounds);
          }

        }

        /*
         *  new_map
         *
         *  This function will render a Google Map onto the selected jQuery element
         *
         *  @type	function
         *  @date	8/11/2013
         *  @since	4.3.0
         *
         *  @param	$el (jQuery element)
         *  @return	n/a
         */

        function new_map($el) {

          // var
          var $markers = $el.find('.marker');


          // vars
          var args = {
            zoom: 16,
            center: new google.maps.LatLng(0, 0),
            mapTypeId: google.maps.MapTypeId.ROADMAP
          };


          // create map
          var map = new google.maps.Map($el[0], args);


          // add a markers reference
          map.markers = [];


          // add markers
          $markers.each(function () {

            add_marker($(this), map);

          });


          // center map
          center_map(map);


          // return
          return map;

        }



        /*
         *  document ready
         *
         *  This function will render each map when the document is ready (page has loaded)
         *
         *  @type	function
         *  @date	8/11/2013
         *  @since	5.0.0
         *
         *  @param	n/a
         *  @return	n/a
         */
// global var
        var map = null;

        $(document).ready(function () {

          $('.acf-map').each(function () {

            // create map
            map = new_map($(this));

          });

        });

      }
    },
    'page_template_product_page_template': {
      init: function () {


        $("#PlatnostForm").submit(function (e) {
          e.preventDefault();

          let rok = parseInt($("#platnostOd_rok").val().replace(/[^\d.]/g, ''));
          let mesiac = parseInt($("#platnostOd_mesiac").val().replace(/[^\d.]/g, '')) - 1;
          let den = parseInt($("#platnostOd_den").val().replace(/[^\d.]/g, ''));
          if (isNaN(rok) || isNaN(mesiac) || isNaN(den)) {
            alert("Prosím, zadajte dátum.");
            return;
          }
          let dnes = new Date();
          //cannot buy past or too far in the future
          if (rok < dnes.getFullYear() || rok > dnes.getFullYear() + 10) {
            alert("Prosím, zadajte rok začiatku platnosti.");
            return;
          }

          let datum = new Date(rok, mesiac, den, 23, 59);
          dnes.setDate(dnes.getDate() + 2);
          if (datum <= dnes) {
            alert("Dátum platnosti musí byť najskôr o 2 dni.");
            return false;
          }
          let unixtime = Date.parse(datum) / 1000;

          addToCart(cartLink, $("#productKey").val(), {valid_from: unixtime});
          return false;
        });


          const isPersonInComplete = (person) => (
            (person.firstName || person.lastName || person.birthNumber)
            && !(person.firstName && person.lastName && person.birthNumber)
          )
          const getAgeFromBirthNumber = (birthNumber) => {
            const currentYear = new Date().getFullYear();
            const birthYearEnd = Number(birthNumber.slice(0, 2))
            const birthYearStart = birthYearEnd > currentYear - 2000 ? 1900 : 2000;
            const birthYear = birthYearStart + birthYearEnd;

            let birthMonth = Number(birthNumber.slice(2, 4));
            if (birthMonth > 50) birthMonth -= 50;

            const birthDay = Number(birthNumber.slice(4, 6));
            const birthDate = new Date(birthYear, (birthMonth > 50 ? birthMonth - 50 : birthMonth) - 1, birthDay);
            const ageDiffMs = Date.now() - birthDate;
            const ageDate = new Date(ageDiffMs);
            return Math.abs(ageDate.getUTCFullYear() - 1970);
          }

         $("#CardForm").submit(function (e) {
          e.preventDefault();
          const $form = $(e.target);
          const cardType = $($form.closest('.modal')).data('type');
          const data = formToObject(e.target);
          const productId = data.key;

          // Validate
          if (!data.CardForm || !data.CardForm.adults) {
            alert("Musíte vyplniť aspoň 1 dospelú osobu.");
            return false;
          }
          // For each row - if any field is filled, all must be filled
          for (const person of Object.values(data.CardForm.adults)) {
            if (isPersonInComplete(person)) {
              alert("Osoba nemá kompletné údaje.");
              return false;
            }
            if (getAgeFromBirthNumber(person.birthNumber) < 18) {
                alert("Člen musí mať aspoň 18 rokov.");
                return false;
              }
          }
          if (data.CardForm.children) {
            for (const person of Object.values(data.CardForm.children)) {
              if (isPersonInComplete(person)) {
                alert("Dieťa nemá kompletné údaje.");
                return false;
              }
              if (getAgeFromBirthNumber(person.birthNumber) >= 18) {
                alert("Deti musia mať menej ako 18 rokov.");
                return false;
              }
            }
          }
          addToCart(cartLink, productId, data.CardForm);
          return false;
         });

         $("#CardForm .add-row").click(function (e) {
          const $button = $(this);
          const $rows = $button.closest('.modal-body').find('.person');
          const count = $rows.length;
          const $template = $rows.first();
          const $newRow = $template.clone();
          $newRow.find('input').each((_i, el) => {
            el.name = el.name.replace('CardForm[adults][0]', 'CardForm[children]['+ String(count - 2) +']');
            $(el).val('');
          });

          $newRow.insertBefore($button.closest('.row').find('~ hr'));
          if (count >= 6) $button.prop('disabled', true);
         });

        $('#myModal').on('show.bs.modal', function (event) {
          var button = $(event.relatedTarget); // Button that triggered the modal
          var recipient = button.data('product'); // Extract info from data-* attributes
          // If necessary, you could initiate an AJAX request here (and then do the updating in a callback).
          // Update the modal's content. We'll use jQuery here, but you could use a data binding library or other methods instead.
          var modal = $(this);
          //console.log(recipient);
          $("#productKey").val(recipient);
          //modal.find('.modal-title').text('New message to ' + recipient);
          //modal.find('.modal-body input').val(recipient);
        });
        $('#modalCardPeople').on('show.bs.modal', function (event) {
          const button = $(event.relatedTarget); // Button that triggered the modal
          const product = button.data('product'); 
          const cardType = button.data('type');
          const $modal = $(this);

          $modal.find('form').trigger('reset');
          $modal.find("#productKey").val(product);
          $modal.data('type', cardType);
          $modal.find('.person:not(:first-of-type)').remove();
          if (cardType === 'single') {
            $modal.find('.add-row').hide();
          } else {
            $modal.find('.add-row').show().prop('disabled', false);
            const tpl = $modal.find('.person:first-of-type');
            const $newRow = tpl.clone();
            $newRow.find('input').each((_i, el) => {
              el.name = el.name.replace('CardForm[adults][0]', 'CardForm[adults][1]');
            });
            $newRow.insertAfter(tpl);
          }
        });
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);


})(jQuery); // Fully reference jQuery after this point.

