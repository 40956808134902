/**
 * Created by pato1 on 18/07/16.
 *
 * Wordpress theme development framework for easier work.
 * benyak.eu
 *
 * Functionality stages:
 * 1. initialization, component injection
 * 2. config loading and parsing
 * 3. event hooks
 * 4. validation
 *
 * tasks:
 * - implement basic components and their extending
 * - loading of settings via AJAX
 *
 */
(function($){

  class Forms {

    constructor (obj, options) {
      this._settings = $.extend(this, options);
      this.element = obj;

      this.formname = obj.attr('id').replace('Form', '').toLowerCase();
      //bind events
      if(this._settings.validation) {

        $.ajax({
          type: "POST",
          url: '/wp/wp-admin/admin-ajax.php', //TODO: export this from within the php
          data: {
            'action': 'ajaxconfig-'+this.formname
          },
          form: this
        }).always(function (response) {
          this.form.rules = JSON.parse(response);
        });


        this.element.on("focusout", "input,select,textarea", {'form': this}, function (e) {
          e.data.form.validateField($(this).attr('name').replace(e.data.form.getName(), '').replace('[', '').replace(']', ''));
        });


      }

      if(this._settings.submission) {
        this.element.on('submit', function (e) {
          e.preventDefault();

         formname = this.forms().formname;

          $.ajax({
            type: "POST",
            url: '/wp/wp-admin/admin-ajax.php', //TODO: export this from within the php
            data: {
              'action': 'submit-'+formname,
              'data': $(this).serialize()
            },
            destination: $(this).parent()
          }).always(function (response) {
            this.destination.html($(response).html());
          });

          $(this).parent().html($("<div>").addClass("ajax-submit-loader"));

        });
      }



      //how to bind only if parent .has-error
      return this;
    }

    validateField(field) {
      console.log(field);
    }

    getName () {
      var id = this.element.attr('id');
      return id.replace('#','');

    }
    static defaults () {
      return {
        'submission': false,
        'validation': false,
        'something': [1,2,4]
      };
    }

    highlightError (field) {
      $("#" + this.getName() + "_" + field).closest('.form-group').addClass('has-error');
    }

    removeError (field) {
      $("#" + this.getName() + "_" + field).closest('.form-group').removeClass('has-error');
    }


  }


  //export to jQuery as a singleton, kinda
  $.fn.forms = function (options) {
    if ( typeof this.data('benyforms') === "undefined")
    {
      this.data('benyforms', new Forms(this, options));
    }
    return this.data('benyforms');
  };



})(jQuery);

window.$=jQuery;
